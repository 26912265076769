import { useState } from "react";
import styles from "./ResponseForm.module.css";
import StringInput from "./FormElements/StringInput";
import Select from "./FormElements/Select";
import Form from "./FormElements/Form";
import Alert, { AlertType } from "./Utils/Alert";
import { MenuResponsesRequestDto, sendMenuResponses } from "./Utils/Api";
import { ValueWithError } from "./FormElements/ValueWithError";

type MenuResponse = {
    id: number,
    name: ValueWithError<string>,
    menu: ValueWithError<string>,
    misc: ValueWithError<string>
}

type MenuResponses = {
    nextId: number,
    values: MenuResponse[],
    error?: string
}

function createMenuResponse(id: number): MenuResponse {
    return { id: id, name: { value: "" }, menu: { value: "" }, misc: { value: "" } };
}

export default function ResponseForm() {
    const [contactName, setContactName] = useState<ValueWithError<string>>({ value: "" });
    const [contactEmail, setContactEmail] = useState<ValueWithError<string>>({ value: "" });
    const [contactPhone, setContactPhone] = useState<ValueWithError<string>>({ value: "" });
    const [isComing, setIsComing] = useState(true);
    const [menuResponses, setMenuResponses] = useState<MenuResponses>({
        values: [],
        nextId: 0
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [hasSubmitSuccess, setHasSubmitSuccess] = useState(false);
    const [hasSubmitError, setHasSubmitError] = useState(false);

    function setNumberOfPersons(value: number): void {
        if (!value) {
            return;
        }
        setMenuResponses(prev => {
            let diff = value - prev.values.length;
            if (diff > 0) {
                let newResponses: MenuResponse[] = [];
                for (let i = 0; i < diff; ++i) {
                    newResponses.push(createMenuResponse(prev.nextId + i));
                }
                return {
                    values: [...prev.values, ...newResponses],
                    nextId: prev.nextId + diff
                }
            }
            if (diff < 0) {
                return {
                    values: prev.values.slice(0, diff),
                    nextId: prev.nextId
                }
            }
            return prev;
        });
    }

    function setMenuResponseName(id: number, name: string): void {
        setMenuResponses(prev => {
            let i = prev.values.findIndex(o => o.id === id);
            let newResponse = { ...prev.values[i], name: { value: name } };
            return {
                values: [...prev.values.slice(0, i), newResponse, ...prev.values.slice(i + 1)],
                nextId: prev.nextId
            }
        });
    }

    function setMenuResponseMenu(id: number, menu: string): void {
        setMenuResponses(prev => {
            let i = prev.values.findIndex(o => o.id === id);
            let newResponse = { ...prev.values[i], menu: { value: menu } };
            return {
                values: [...prev.values.slice(0, i), newResponse, ...prev.values.slice(i + 1)],
                nextId: prev.nextId
            }
        });
    }

    function setMenuResponseMisc(id: number, misc: string): void {
        setMenuResponses(prev => {
            let i = prev.values.findIndex(o => o.id === id);
            let newResponse = { ...prev.values[i], misc: { value: misc } };
            return {
                values: [...prev.values.slice(0, i), newResponse, ...prev.values.slice(i + 1)],
                nextId: prev.nextId
            }
        });
    }

    function validate(): boolean {
        let isValid = true;
        if (!contactName.value) {
            isValid = false;
            setContactName(prev => {
                return { ...prev, error: "Familienname darf nicht leer sein." }
            });
        }
        if (isComing) {
            let error: string | undefined = undefined;
            if (menuResponses.values.length === 0) {
                isValid = false;
                error = "Anzahl Personen muss ausgewählt sein.";
            }
            let newResponses = menuResponses.values.map(o => {
                let newResponse = { ...o };
                if (!o.name.value) {
                    isValid = false;
                    newResponse.name = { ...o.name, error: "Name darf nicht leer sein." };
                }
                if (!o.menu.value) {
                    isValid = false;
                    newResponse.menu = { ...o.menu, error: "Menüwunsch darf nicht leer sein." };
                }
                return newResponse;
            });
            setMenuResponses(prev => {
                return { ...prev, values: newResponses, error: error };
            });
        }
        return isValid;
    }

    async function submit() {
        setHasSubmitSuccess(false);
        setHasSubmitError(false);
        let isValid = validate();
        if (isValid) {
            let requestDto: MenuResponsesRequestDto = {
                contactName: contactName.value,
                contactEmail: contactEmail.value,
                contactPhone: contactPhone.value,
                isComing: isComing
            }
            if (isComing) {
                requestDto = {
                    ...requestDto,
                    responses: menuResponses.values.map(o => {
                        return {
                            name: o.name.value,
                            menu: o.menu.value,
                            misc: o.misc.value
                        }
                    })
                };
            }

            setIsSubmitting(true);
            try {
                await sendMenuResponses(requestDto);
                setHasSubmitSuccess(true);
            }
            catch (ex) {
                setHasSubmitError(true);
                console.log("Failed to send menu responses.", ex);
            }
            finally {
                setIsSubmitting(false);
            }
        }
    }

    return <Form isDisabled={isSubmitting || hasSubmitSuccess}>
        <div className={styles.topGroup}>
            <div className={styles.contactGroup}>
                <div className="form-row">
                    <StringInput placeholder="Familienname"
                        value={contactName.value}
                        error={contactName.error}
                        onChange={event => setContactName({ value: event.target.value })} />
                </div>
                <div className="form-row">
                    <StringInput placeholder="E-Mail"
                        value={contactEmail.value}
                        error={contactEmail.error}
                        onChange={event => setContactEmail({ value: event.target.value })} />
                </div>
                <div className="form-row">
                    <StringInput placeholder="Telefonnr."
                        value={contactPhone.value}
                        error={contactPhone.error}
                        onChange={event => setContactPhone({ value: event.target.value })} />
                </div>
                <div className="form-row">
                    <Select error={menuResponses.error} value={menuResponses.values.length} onChange={e => setNumberOfPersons(parseInt(e.target.value))}>
                        <option value="0" hidden={true}>Anzahl Personen</option>
                        <option value="1">1 Person</option>
                        <option value="2">2 Personen</option>
                        <option value="3">3 Personen</option>
                        <option value="4">4 Personen</option>
                        <option value="5">5 Personen</option>
                        <option value="6">6 Personen</option>
                        <option value="7">7 Personen</option>
                        <option value="8">8 Personen</option>
                    </Select>
                </div>
            </div>
            <div className={styles.isComingGroup}>
                <div className="form-row form-radio">
                    <input type="radio"
                        value="1"
                        name="responseForm[isComing]"
                        id="responseForm_isComing_1"
                        checked={isComing}
                        onChange={e => setIsComing(e.target.checked)} />
                    <label htmlFor="responseForm_isComing_1">{menuResponses.values.length === 1 ? "Ich nehme teil" : "Wir nehmen teil"}</label>
                </div>
                <div className="form-row form-radio">
                    <input type="radio"
                        value="0"
                        name="responseForm[isComing]"
                        id="responseForm_isComing_0"
                        checked={!isComing}
                        onChange={e => setIsComing(!e.target.checked)} />
                    <label htmlFor="responseForm_isComing_0">{menuResponses.values.length === 1 ? "Ich kann leider nicht kommen" : "Wir können leider nicht kommen"}</label>
                </div>
            </div>
        </div>
        {isComing
            ? menuResponses.values.map((menuResponse, index) =>
                <div key={menuResponse.id} className={styles.menuResponse}>
                    <div className="form-row">
                        <p>Gast {index + 1}:</p>
                    </div>
                    <div className="form-row">
                        <StringInput placeholder="Name"
                            value={menuResponse.name.value}
                            error={menuResponse.name.error}
                            onChange={event => setMenuResponseName(menuResponse.id, event.target.value)} />
                        <Select error={menuResponse.menu.error} value={menuResponse.menu.value} onChange={e => setMenuResponseMenu(menuResponse.id, e.target.value)}>
                            <option value="" hidden={true}>Menüwunsch</option>
                            <option value="Fleisch">Fleisch (Geschmortes Weiderind mit Stangenbohnen und Thymiankartoffeln)</option>
                            <option value="Fisch">Fisch (Fang des Tages mit Spargelrisotto)</option>
                            <option value="Vegetarisch">Vegetarisch (Tagliatelle mit Bärlauchpesto und Cherrytomaten)</option>
                            <option value="Kinderkarte">Kinderkarte (bis 12 Jahre)</option>
                            <option value="Baby">Baby (ohne Essen)</option>
                        </Select>
                    </div>
                    <div className="form-row">
                        <textarea placeholder="Unverträglichkeiten, Anmerkungen, Wünsche"
                            value={menuResponse.misc.value}
                            onChange={event => setMenuResponseMisc(menuResponse.id, event.target.value)}></textarea>
                    </div>
                </div>
            ) : ''}
        <div className="form-row">
            <button type="button" onClick={submit} className="btn btn-primary" disabled>Abschicken</button>
        </div>
        <div className={`fadeable ${isSubmitting ? "visible" : ""}`}>
            <p><i className="fas fa-spinner fa-spin"></i> Rückmeldung wird abgeschickt.</p>
        </div>
        <Alert type={AlertType.Success} className={`fadeable ${hasSubmitSuccess ? "visible" : ""}`}>
            <p className="align-center">Rückmeldung erfolgreich abgeschickt!</p>
        </Alert>
        <Alert type={AlertType.Danger} className={`fadeable ${hasSubmitError ? "visible" : ""}`}>
            <p className="align-center">Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut!</p>
        </Alert>
    </Form>
}
