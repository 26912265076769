import { MouseEvent, useEffect, useRef, useState } from "react";
import styles from "./NavMenu.module.css";
import StickyBar from "./StickyBar";
import UlHScroll from "./UlHScroll";

type NavAnchor = {
    id: string,
    text: string
}

type NavMenuProps = {
    brand: NavAnchor,
    items: NavAnchor[]
}

function scrollToTarget(elementId: string, offset: number) {
    const element = document.getElementById(elementId);
    if (element) {
        const pos = element.getBoundingClientRect().top + window.scrollY - offset;
        window.scrollTo({ top: pos });
    }
}

export default function NavMenu(props: NavMenuProps) {
    const navElement = useRef<HTMLElement>(null);
    const [activeItemIndex, setActiveItemIndex] = useState(0);

    function scrollToTargetYWithoutOffset(event: MouseEvent, elementId: string) {
        event.preventDefault();
        scrollToTarget(elementId, 0)
    }

    function scrollToTargetYWithOffset(event: MouseEvent, elementId: string) {
        event.preventDefault();
        scrollToTarget(elementId, getNavHeight());
    }

    function getNavHeight(): number {
        return navElement.current?.getBoundingClientRect().height ?? 0;
    }

    useEffect(() => {
        function scrollToActiveItem() {
            let navHeight = getNavHeight();
            let bound = window.innerHeight / 2;
            let newIndex = 0;
            for (; newIndex < props.items.length; ++newIndex) {
                let itemElement = document.getElementById(props.items[newIndex].id);
                if (itemElement) {
                    let top = itemElement.getBoundingClientRect().top;
                    if (top >= navHeight) {
                        if (top > bound) {
                            --newIndex;
                        }
                        break;
                    }
                }
            }
            newIndex = Math.max(0, Math.min(newIndex, props.items.length - 1));
            setActiveItemIndex(newIndex);
        }

        scrollToActiveItem();

        window.addEventListener("scroll", scrollToActiveItem);
        return () => {
            window.removeEventListener("scroll", scrollToActiveItem);
        }
    });

    return <StickyBar>
        <nav ref={navElement} className={styles.nav}>
            <a className={styles.names} href={"#" + props.brand.id} onClick={event => scrollToTargetYWithoutOffset(event, props.brand.id)}>{props.brand.text}</a>
            <UlHScroll activeItemId={props.items[activeItemIndex]?.id} activeItemIndex={activeItemIndex}>
                {props.items.map((item, index) =>
                    <li key={item.id}>
                        <a href={"#" + item.id} className={styles.menuLink + ` ${index === activeItemIndex ? styles.active : ""}`} onClick={event => scrollToTargetYWithOffset(event, item.id)}>{item.text}</a>
                    </li>
                )}
            </UlHScroll>
        </nav>
    </StickyBar>
}
