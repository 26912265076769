import { DetailedHTMLProps, PropsWithChildren, SelectHTMLAttributes } from "react";
import styles from "./Select.module.css";

type StringInputProps = {
    error?: string,
}

export default function Select(props: PropsWithChildren<StringInputProps & DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>>) {
    return <div className={styles.selectContainer}>
        <div className={`${styles.selectIcon} ${props.error ? styles.invalid : styles.valid}`}><i className="fas fa-angle-down" /></div>
        <select className={props.error ? "invalid" : "valid"} {...props}>
            {props.children}
        </select>
        {props.error ? <div className="validation-message">{props.error}</div> : ''}
    </div>
}
