import { PropsWithChildren } from "react";
import styles from "./Alert.module.css";

export enum AlertType {
    Success = "alertSuccess",
    Danger = "alertDanger"
}

type AlertProps = {
    type: AlertType,
    className?: string
}

export default function Alert(props: PropsWithChildren<AlertProps>) {
    return <div className={`${styles.alert} ${styles[props.type]} ${props.className}`}>
        {props.children}
    </div>
}
