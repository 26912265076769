import { Buffer } from "buffer";
import styles from "./LocationMap.module.css";

type LocationMapProps = {
    title: string,
    subtitle: string,
    text: string,
    address?: string,
    imagePath: string
}

function getAddressUri(address?: string) {
    return address ? `https://www.google.com/maps?q=${encodeURIComponent(address)}` : "#";
}

function getImagePathHash(imagePath: string) {
    let imagePathBytes = Buffer.from(imagePath, "utf-8");
    return imagePathBytes.toString("base64");
}

export default function LocationMap(props: LocationMapProps) {
    return <div className={styles.locationMap}>
        <div className={styles.locationMapImage}>
            <a className={styles.locationMapLink} href={getAddressUri(props.address)} target="_blank" rel="noreferrer">
                <svg width="200" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <pattern id={`img-location-${getImagePathHash(props.imagePath)}`} height="100%" width="100%" patternUnits="userSpaceOnUse">
                            <image xlinkHref={props.imagePath} width="100%" height="100%" preserveAspectRatio="xMidYMid slice"></image>
                        </pattern>
                    </defs>
                    <polygon fill={`url(#img-location-${getImagePathHash(props.imagePath)})`} points="50 1 95 25 95 75 50 99 5 75 5 25"></polygon>
                    <polygon className={styles.overlay} points="50 1 95 25 95 75 50 99 5 75 5 25" fillOpacity="0"></polygon>
                </svg>
                <i className={`${styles.overlayIcon} fa-solid fa-location-dot`}></i>
            </a>
        </div>
        <div className={styles.locationDetails}>
            <h3 className={styles.locationTitle}>{props.title}</h3>
            <p className={styles.locationSubtitle}>{props.subtitle}</p>
            <p className="location-text">{props.text}</p>
            <p className={styles.locationUri}><strong>Adresse:</strong> <a href={getAddressUri(props.address)} target="_blank" rel="noreferrer">{props.address}</a></p>
        </div>
    </div>
}
