const backendBaseUri = process.env.REACT_APP_BACKEND_BASE_URI;

export type MenuResponseRequestDto = {
    name: string,
    menu: string,
    misc: string
}

export type MenuResponsesRequestDto = {
    contactName: string,
    contactEmail: string,
    contactPhone: string,
    isComing: boolean,
    responses?: MenuResponseRequestDto[]
}

export type MusicWishResponseRequestDto = {
    interpreter?: string
    title: string
}

export type MusicWishResponsesRequestDto = {
    wishes: MusicWishResponseRequestDto[]
}

function createJsonRequest(payload: any): RequestInit {
    let payloadString = JSON.stringify(payload);
    return {
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        body: payloadString
    }
}

async function send(relativeUri: string, payload: any): Promise<void> {
    if (!backendBaseUri) {
        throw Error("Backend base uri not configured.");
    }

    let uri = `${backendBaseUri}${relativeUri}`;
    let request = createJsonRequest(payload);
    let response = await fetch(uri, request);
    if (!response.ok) {
        let responseText = await response.text();
        throw Error(`Response is not ok. ${responseText}`);
    }
}

export async function sendMenuResponses(requestDto: MenuResponsesRequestDto): Promise<void> {
    await send("/api/MenuResponse", requestDto);
}

export async function sendMusicWishResponses(requestDto: MusicWishResponsesRequestDto): Promise<void> {
    await send("/api/MusicWish", requestDto);
}
