import styles from "./OrganizerItem.module.css";

type OrganizerItemProps = {
    name: string,
    subtitle?: string,
    email?: string,
    phone?: string,
    description?: string,
    imagePath?: string
}

export default function OrganizerItem(props: OrganizerItemProps) {
    return <div className={styles.organizerItem}>
        {props.imagePath ? <img src={props.imagePath} className={styles.organizerImage} alt={`${props.name}, ${props.subtitle}`} /> : ""}
        <h3 className={styles.organizerName}>{props.name}</h3>
        {props.subtitle ? <p className={styles.organizerSubtitle}>{props.subtitle}</p> : ""}
        {props.email ? <p className={styles.organizerEmail}><i className="fas fa-envelope"></i> <a href={`mailto:${props.email}`}>{props.email}</a></p> : ""}
        {props.phone ? <p className={styles.organizerPhone}><i className="fas fa-phone-flip"></i> {props.phone}</p> : ""}
        {props.description ? <p className={styles.organizerDescription}>{props.description}</p> : ""}
    </div>
}