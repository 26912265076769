import { PropsWithChildren } from "react"

type FormProps = {
    isDisabled?: boolean
}

export default function Form(props: PropsWithChildren<FormProps>) {
    return <form>
        <fieldset disabled={props.isDisabled}>
            {props.children}
        </fieldset>
    </form>
}
