import useResizeObserver from "@react-hook/resize-observer";
import { PropsWithChildren, useRef, useState } from "react";
import styles from "./UlHScroll.module.css";

type UlHScrollProps = {
    activeItemId?: string,
    activeItemIndex: number
}

export default function UlHScroll(props: PropsWithChildren<UlHScrollProps>) {
    const scrollOuterElement = useRef<HTMLDivElement>(null);
    const scrollInnerElement = useRef<HTMLUListElement>(null);
    const [offsetX, setOffsetX] = useState(0);
    const [areButtonsVisible, setAreButtonsVisible] = useState(false);
    const [isLeftButtonEnabled, setIsLeftButtonEnabled] = useState(false);
    const [isRightButtonEnabled, setIsRightButtonEnabled] = useState(false);
    const [activeItemId, setActiveItemId] = useState<string>();

    function setOffsetXAndUpdateButtonStates(newOffsetX: number) {
        setOffsetX(newOffsetX);

        let scrollOuter = scrollOuterElement.current;
        let scrollInner = scrollInnerElement.current;
        if (!scrollOuter || !scrollInner) {
            return;
        }

        let d = scrollInner.offsetWidth - scrollOuter.offsetWidth;
        setAreButtonsVisible(d > 0);
        setIsLeftButtonEnabled(newOffsetX > 0);
        setIsRightButtonEnabled(newOffsetX < d);
    }

    function scrollToLeft() {
        let scrollOuter = scrollOuterElement.current;
        let scrollInner = scrollInnerElement.current;
        if (!scrollOuter || !scrollInner) {
            return;
        }
        let liElements = scrollInner.getElementsByTagName("li");
        if (liElements.length === 0) {
            return;
        }

        // Find target item, which should be brought into view.
        // This is the first element from the right which is not fully visible.
        let scrollIndex = liElements.length - 1;
        while (scrollIndex > 0 && liElements[scrollIndex].offsetLeft >= offsetX) {
            --scrollIndex;
        }

        // Make more items fully visible while keeping the target item fully visible.
        let targetItem = liElements[scrollIndex];
        let minOffsetLeft = targetItem.offsetLeft + targetItem.offsetWidth - scrollOuter.offsetWidth;
        while (scrollIndex > 0 && liElements[scrollIndex - 1].offsetLeft >= minOffsetLeft) {
            --scrollIndex;
        }

        let newOffsetX = liElements[scrollIndex].offsetLeft;
        setOffsetXAndUpdateButtonStates(newOffsetX);
    }

    function scrollToRight() {
        let scrollOuter = scrollOuterElement.current;
        let scrollInner = scrollInnerElement.current;
        if (!scrollOuter || !scrollInner) {
            return;
        }
        let liElements = scrollInner.getElementsByTagName("li");
        if (liElements.length === 0) {
            return;
        }

        // Find target item, which should be brought into view.
        // This is the first element from the left which is not fully visible.
        let scrollIndex = 0;
        while (scrollIndex + 1 < liElements.length && liElements[scrollIndex + 1].offsetLeft <= offsetX + scrollOuter.offsetWidth) {
            ++scrollIndex;
        }

        // Scroll such that it is completely on the left.
        let maxOffsetLeft = scrollInner.offsetWidth - scrollOuter.offsetWidth;
        let newOffsetX = Math.max(0, Math.min(maxOffsetLeft, liElements[scrollIndex].offsetLeft));
        setOffsetXAndUpdateButtonStates(newOffsetX);
    }

    function scrollToItem(scrollIndex: number) {
        if (!areButtonsVisible) {
            return;
        }
        let scrollOuter = scrollOuterElement.current;
        let scrollInner = scrollInnerElement.current;
        if (!scrollOuter || !scrollInner) {
            return;
        }
        let liElements = scrollInner.getElementsByTagName("li");
        if (liElements.length === 0) {
            return;
        }

        scrollIndex = Math.max(0, Math.min(scrollIndex, liElements.length - 1));
        let targetItem = liElements[scrollIndex];
        if (targetItem.offsetLeft < offsetX) {
            // Scroll target is not visible and is on the left.
            // Make more items fully visible while keeping the target item fully visible.
            let targetItem = liElements[scrollIndex];
            let minOffsetLeft = targetItem.offsetLeft + targetItem.offsetWidth - scrollOuter.offsetWidth;
            while (scrollIndex > 0 && liElements[scrollIndex - 1].offsetLeft >= minOffsetLeft) {
                --scrollIndex;
            }

            let newOffsetX = liElements[scrollIndex].offsetLeft;
            setOffsetXAndUpdateButtonStates(newOffsetX);
        } else if (liElements[scrollIndex].offsetLeft + liElements[scrollIndex].offsetWidth > offsetX + scrollOuter.offsetWidth) {
            // Scroll target is not visible and is on the right.
            // Scroll such that it is completely on the left.
            let maxOffsetLeft = scrollInner.offsetWidth - scrollOuter.offsetWidth;
            let newOffsetX = Math.max(0, Math.min(maxOffsetLeft, liElements[scrollIndex].offsetLeft));
            setOffsetXAndUpdateButtonStates(newOffsetX);
        }
    }

    function getClampedOffsetX(value: number): number {
        if (value <= 0) {
            return 0;
        }
        let scrollOuter = scrollOuterElement.current;
        let scrollInner = scrollInnerElement.current;
        if (!scrollOuter || !scrollInner) {
            return value;
        }
        let maxOffsetX = scrollInner.offsetWidth - scrollOuter.offsetWidth;
        if (value >= maxOffsetX) {
            return maxOffsetX;
        }
        return value;
    }

    useResizeObserver(scrollOuterElement, entry => {
        let newOffsetX = getClampedOffsetX(offsetX);
        setOffsetXAndUpdateButtonStates(newOffsetX);
    });

    if (props.activeItemId !== activeItemId) {
        setActiveItemId(props.activeItemId);
        scrollToItem(props.activeItemIndex);
    }

    return <div className={`${styles.wrapper} ${areButtonsVisible ? "" : styles.unscrollable}`}>
        <button onClick={scrollToLeft} className={styles.scrollButton + ` btn-link`} disabled={!isLeftButtonEnabled}><i className="fas fa-angle-left" /></button>
        <div ref={scrollOuterElement} className={styles.scrollOuter}>
            <ul ref={scrollInnerElement} className={styles.scrollInner} style={{ transform: `translateX(${-offsetX}px)` }}>
                {props.children}
            </ul>
        </div>
        <button onClick={scrollToRight} className={styles.scrollButton + ` btn-link`} disabled={!isRightButtonEnabled}><i className="fas fa-angle-right" /></button>
    </div>
}
