import { PropsWithChildren, useRef, useState } from "react";
import styles from "./AccordionItem.module.css";
import useResizeObserver from "@react-hook/resize-observer";

type AccordionItemProps = {
    title: string
}

export default function AccordionItem(props: PropsWithChildren<AccordionItemProps>) {
    const contentInnerElement = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);

    useResizeObserver(contentInnerElement, entry => {
        setContentHeight(entry.contentRect.height);
    })

    function toggleVisibility() {
        setIsOpen(prev => !prev);
    }

    return <li className={`${styles.accordionItem} ${isOpen ? styles.open : ""}`}>
        <button onClick={() => toggleVisibility()} className={styles.title}><i className="fas fa-angle-right"></i> {props.title}</button>
        <div className={styles.accordionContentOuter} style={{ height: `${isOpen ? contentHeight : 0}px` }}>
            <div ref={contentInnerElement} className={styles.accordionContentInner}>
                {props.children}
            </div>
        </div>
    </li>
}
