import styles from "./WeddingAbcItem.module.css";

type WeddingAbcItemProps = {
    letter: string,
    text: string
}

export default function WeddingAbcItem(props: WeddingAbcItemProps) {
    return <li className={styles.item}>
        <div className="abc-letter-wrap"><div className={styles.letter}>{props.letter}</div></div>
        <p className={styles.text}>{props.text}</p>
    </li>
}
