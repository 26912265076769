import { useState } from "react";
import Form from "./FormElements/Form";
import styles from "./MusicWishForm.module.css";
import { ValueWithError } from "./FormElements/ValueWithError";
import StringInput from "./FormElements/StringInput";
import Alert, { AlertType } from "./Utils/Alert";
import { MusicWishResponsesRequestDto, sendMusicWishResponses } from "./Utils/Api";

type MusicWishResponse = {
    id: number,
    interpreter: ValueWithError<string>,
    title: ValueWithError<string>
}

type MusicWishResponses = {
    nextId: number,
    values: MusicWishResponse[]
}

function createMusicWishResponse(id: number): MusicWishResponse {
    return { id: id, interpreter: { value: "" }, title: { value: "" } };
}

export default function MusicWishForm() {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [hasSubmitSuccess, setHasSubmitSuccess] = useState(false);
    const [hasSubmitError, setHasSubmitError] = useState(false);
    const [musicWishResponses, setMusicWishResponses] = useState<MusicWishResponses>({
        values: [
            createMusicWishResponse(0),
            createMusicWishResponse(1),
            createMusicWishResponse(2),
        ],
        nextId: 3
    });

    function setMusicWishResponseInterpreter(id: number, interpreter: string): void {
        setMusicWishResponses(prev => {
            let i = prev.values.findIndex(o => o.id === id);
            let newResponse = { ...prev.values[i], interpreter: { value: interpreter } };
            return {
                values: [...prev.values.slice(0, i), newResponse, ...prev.values.slice(i + 1)],
                nextId: prev.nextId
            }
        });
    }

    function setMusicWishResponseTitle(id: number, title: string): void {
        setMusicWishResponses(prev => {
            let i = prev.values.findIndex(o => o.id === id);
            let newResponse = { ...prev.values[i], title: { value: title } };
            return {
                values: [...prev.values.slice(0, i), newResponse, ...prev.values.slice(i + 1)],
                nextId: prev.nextId
            }
        });
    }

    function clearInputData() {
        setMusicWishResponses(prev => {
            return {
                values: [
                    createMusicWishResponse(prev.nextId),
                    createMusicWishResponse(prev.nextId + 1),
                    createMusicWishResponse(prev.nextId + 2)
                ],
                nextId: prev.nextId + 3
            }
        });
    }

    function validate(): boolean {
        let isValid = true;
        let error: string | undefined = undefined;
        let newResponses = musicWishResponses.values.map(o => {
            let newResponse = { ...o };
            let newInterpreterError: string | undefined = undefined;
            if (!o.interpreter.value && o.title.value) {
                isValid = false;
                newInterpreterError = "Interpret darf nicht leer sein.";
            }
            newResponse.interpreter = { ...o.interpreter, error: newInterpreterError };
            let newTitleError: string | undefined = undefined;
            if (o.interpreter.value && !o.title.value) {
                isValid = false;
                newTitleError = "Titel darf nicht leer sein.";
            }
            newResponse.title = { ...o.title, error: newTitleError };
            return newResponse;
        });
        let hasResponse = musicWishResponses.values.some(o => o.interpreter.value || o.title.value);
        if (!hasResponse) {
            isValid = false;
            newResponses[0].interpreter = { ...newResponses[0].interpreter, error: "Mindestens ein Musikwünsche muss ausgefüllt sein." };
        }
        setMusicWishResponses(prev => {
            return { ...prev, values: newResponses, error: error };
        });
        return isValid;
    }

    async function submit() {
        setHasSubmitSuccess(false);
        setHasSubmitError(false);
        let isValid = validate();
        if (isValid) {
            const requestDto: MusicWishResponsesRequestDto = {
                wishes: musicWishResponses.values
                    .filter(o => o.title.value)
                    .map(o => {
                        return {
                            interpreter: o.interpreter.value,
                            title: o.title.value
                        }
                    })
            }

            setIsSubmitting(true);
            try {
                await sendMusicWishResponses(requestDto);
                setHasSubmitSuccess(true);
                clearInputData();
            }
            catch (ex) {
                setHasSubmitError(true);
                console.log("Failed to send music wishes.", ex);
            }
            finally {
                setIsSubmitting(false);
            }
        }
    }

    return <Form isDisabled={isSubmitting}>
        {musicWishResponses.values.map((musicWishResponse) =>
            <div key={musicWishResponse.id} className={styles.musicWishRow}>
                <div className="form-row">
                    <StringInput placeholder="Interpret"
                        value={musicWishResponse.interpreter.value}
                        error={musicWishResponse.interpreter.error}
                        onChange={event => setMusicWishResponseInterpreter(musicWishResponse.id, event.target.value)} />
                </div>
                <div className="form-row">
                    <StringInput placeholder="Titel"
                        value={musicWishResponse.title.value}
                        error={musicWishResponse.title.error}
                        onChange={event => setMusicWishResponseTitle(musicWishResponse.id, event.target.value)} />
                </div>
            </div>
        )}
        <div className="form-row">
            <button type="button" onClick={submit} className="btn btn-primary" disabled>Abschicken</button>
        </div>
        <div className={`fadeable ${isSubmitting ? "visible" : ""}`}>
            <p><i className="fas fa-spinner fa-spin"></i> Musikwünsche werden abgeschickt.</p>
        </div>
        <Alert type={AlertType.Success} className={`fadeable ${hasSubmitSuccess ? "visible" : ""}`}>
            <p className="align-center">Musikwünsche erfolgreich abgeschickt!</p>
        </Alert>
        <Alert type={AlertType.Danger} className={`fadeable ${hasSubmitError ? "visible" : ""}`}>
            <p className="align-center">Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut!</p>
        </Alert>
    </Form>
}
