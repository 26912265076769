import styles from "./Jumbotron.module.css"

type JumbotronProps = {
    imagePath: string,
    imageAlt: string,
    title: string,
    subtitle: string
}

export default function Jumbotron(props: JumbotronProps) {
    return <div className={styles.content}>
        <img className={styles.jumbotronImage}
        src={props.imagePath}
        alt={props.imageAlt} />
        <div className={styles.jumbotronText}>
            <h1 className={styles.jumbotronTitle}>{props.title}</h1>
            <div className={styles.separator}>
                <div className={styles.separatorIcons}>
                    <i className="fas fa-heart"></i>
                    <i className="fas fa-heart"></i>
                    <i className="fas fa-heart"></i>
                </div>
            </div>
            <h2 className={styles.jumbotronSubtitle}>{props.subtitle}</h2>
        </div>
    </div>
}