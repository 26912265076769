import LocationMap from './LocationMap';
import gm_kirche_200 from './img/gm_kirche_200.png'
import gm_lammershof_200 from './img/gm_lammershof_200.png'
import tz_all from './img/tz_all3.jpg'
import jumbo_wedding_small from './img/ip_denmark_erased.jpg'
import NavMenu from './NavMenu';
import ResponseForm from './ResponseForm';
import WeddingAbc from './WeddingAbc';
import WeddingAbcItem from './WeddingAbcItem';
import MusicWishForm from './MusicWishForm';
import Organizers from './Organizers';
import OrganizerItem from './OrganizerItem';
import Jumbotron from './Jumbotron';
import Accordion from './Utils/Accordion';
import AccordionItem from './Utils/AccordionItem';

export default function App() {
  return (
    <div className="App">
      <section id="section-header" className="section-header">
        <Jumbotron imagePath={jumbo_wedding_small}
          imageAlt="Das Hochzeitspaar"
          title="Isabelle und Philip heiraten"
          subtitle="Am 18. Mai 2024 ist es soweit!" />
      </section>
      <NavMenu brand={{ id: "section-header", text: "Isabelle & Philip" }}
        items={[
          { id: "section-invitation", text: "Einladung" },
          { id: "section-response", text: "Rückmeldung" },
          { id: "section-locations", text: "Locations" },
          { id: "section-music-requests", text: "Musikwünsche" },
          { id: "section-witness", text: "Trauzeugen" },
          { id: "section-presents", text: "Wünsche" },
          { id: "section-wedding-abc", text: "Hochzeits-ABC" },
          { id: "section-faq", text: "FAQ" },
        ]} />
      <section id="section-invitation" className="align-center">
        <div className="container">
          <h2 className="section-headline h-primary">Danke!</h2>
          <p>
            Liebe Hochzeitsgäste,<br /><br />
            Von Herzen möchten wir uns bei euch für diesen unvergesslichen Tag,<br />
            die liebevollen Glückwünsche und wunderbaren Geschenke bedanken.<br /><br />
            Ihr habt dazu beigetragen, dass unsere Hochzeit ein besonders schönes Fest wurde.<br />
            Danke für die vielen helfenden Hände und dass ihr euch mit uns gefreut und gelacht habt.<br /><br />
            Alle Fotos von unserem Tag findet ihr beim <a href={`${process.env.PUBLIC_URL}/fotodownload`} target="_blank" rel="noreferrer">Fotodownload</a>.<br /><br />
            Liebe Grüße<br />
            Isabelle und Philip
          </p>
        </div>
      </section>
      <section id="section-response" className="align-center header-section">
        <div className="container">
          <h2 className="section-headline">Menü &amp; Rückmeldung</h2>
          <p>Bitte gebt uns eine Rückmeldung bis zum 31. März 2024.</p>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="align-center mb-5">
            <p>Beim Hauptgang könnt ihr wählen zwischen:</p>
            <ul className="ul-menu">
              <li>Geschmortem Weiderind mit Stangenbohnen und Thymiankartoffeln,</li>
              <li>Fang des Tages mit Spargelrisotto, voraussichtlich Kabeljau oder Rotbarsch,</li>
              <li>Tagliatelle mit Bärlauchpesto und Cherrytomaten.</li>
            </ul>
            <p>Für unsere kleinen Gäste gibt es eine Kinderkarte.</p>
          </div>
          <ResponseForm />
        </div>
      </section>
      <section id="section-locations" className="align-center header-section">
        <div className="container">
          <h2 className="section-headline">Locations</h2>
          <p>Hier findet ihr Details zu den Locations sowie Anreise-Informationen.</p>
        </div>
      </section>
      <section>
        <div className="container">
          <LocationMap title="Kirchliche Trauung"
            subtitle="Evangelische Kirche Birkenau, 18. Mai 2024 um 13:30 Uhr"
            text="Parkmöglichkeiten gibt es am Platz la Rochefoucauld und am Bahnhof."
            address="Kirchgasse 10, 69488 Birkenau"
            imagePath={gm_kirche_200} />
          <LocationMap title="Hochzeitsfeier"
            subtitle="Landhotel Lammershof, 18. Mai 2024 um ca. 16:00 Uhr"
            text="Auf dem Hof und dem Parkplatz gegenüber sind ausreichend Parkplätze vorhanden."
            address="Lammershof, Abtsteinacher Str. 2, 69488 Birkenau-Löhrbach"
            imagePath={gm_lammershof_200} />
        </div>
      </section>
      <section id="section-music-requests" className="align-center header-section">
        <div className="container">
          <h2 className="section-headline">Musikwünsche</h2>
          <p>Falls euch 3 Musikwünsche nicht reichen, könnt ihr das Formular auch mehrfach abschicken ;-)</p>
        </div>
      </section>
      <section>
        <div className="container">
          <MusicWishForm />
        </div>
      </section>
      {/* <section id="section-photo-upload" className="align-center header-section">
        <div className="container">
          <h2 className="section-headline">Fotoupload</h2>
          <p>Wir freuen uns riesig über schöne Fotos, die während unserer Hochzeit entstehen.</p>
        </div>
      </section>
      <section>
        <div className="container">
          <p>TODO</p>
        </div>
      </section> */}
      <section id="section-witness" className="align-center header-section">
        <div className="container">
          <h2 className="section-headline">Unsere Trauzeugen</h2>
          <p>Bitte wendet euch an unsere Trauzeugen, wenn ihr "vertrauliche" Fragen vor der Hochzeit habt :-)</p>
        </div>
      </section>
      <section>
        <div className="container">
          <Organizers imagePath={tz_all} imageAlt="Das Brautpaar mit Trauzeugen">
            <OrganizerItem name="Katharina"
              subtitle="Trauzeugin von Isabelle"
              description="&quot;Rina und ich haben uns an der Uni kennengelernt und haben eine Menge erlebt! Sie hat immer ein offenes Ohr für mich und ich weiß, dass ich mich immer auf sie verlassen kann.&quot; - Isabelle" />
            <OrganizerItem name="Viola"
              subtitle="Trauzeugin von Philip"
              description="&quot;Mit Isabelles Schwester Viola habe ich mich sofort gut verstanden! Dass es jemanden gibt, der noch mehr Quatsch macht als ich, hätte ich nicht gedacht.&quot; - Philip" />
          </Organizers>
        </div>
      </section>
      <section id="section-presents" className="align-center header-section">
        <div className="container">
          <h2 className="section-headline">Wünsche</h2>
        </div>
      </section>
      <section className="align-center">
        <div className="container">
          <p>
            Das größte Geschenk für uns ist, diesen wundervollen Tag mit euch zusammen zu feiern!
            Wenn ihr uns dennoch unterstützen wollt, freuen wir uns über einen Beitrag für unsere Flitterwochen.
          </p>
        </div>
      </section>
      <section id="section-wedding-abc" className="align-center header-section">
        <div className="container">
          <h2 className="section-headline">Unser Hochzeits-ABC</h2>
        </div>
      </section>
      <section>
        <div className="container">
          <WeddingAbc>
            <WeddingAbcItem letter="A" text="Autokorso: Eine Kolonne mit Hupkonzert finden wir klasse." />
            <WeddingAbcItem letter="B" text="Brautstrauß wird nicht geworfen, sondern bleibt bei der Braut." />
            <WeddingAbcItem letter="C" text="Chemie: Stimmt." />
            <WeddingAbcItem letter="D" text="Dresscode gibt es keinen. Kommt, wie ihr euch wohlfühlt." />
            <WeddingAbcItem letter="E" text="Essen ist im Lammershof besonders gut." />
            <WeddingAbcItem letter="F" text="Fotos: Wir schauen in der Kirche lieber in fröhliche Gesichter als in Handykameras." />
            <WeddingAbcItem letter="G" text="Gästebuch in besonderer Form: Eure Fingerabdrücke sind gefragt." />
            <WeddingAbcItem letter="H" text="Hochzeitstorte wäre, wenn es nur nach Philips Gelüsten ginge, mindestens achtstöckig." />
            <WeddingAbcItem letter="I" text="Ideen für Hochzeitsspiele bitte mit den Trauzeugen besprechen." />
            <WeddingAbcItem letter="J" text="JA - das wichtigste Wort des Tages." />
            <WeddingAbcItem letter="K" text="Kaffee gibt's nach der Kirche." />
            <WeddingAbcItem letter="L" text="Love is in the air." />
            <WeddingAbcItem letter="M" text="Musik vorhanden, bitte tanzen." />
            <WeddingAbcItem letter="N" text="Nachwuchs ist auch mit dabei." />
            <WeddingAbcItem letter="O" text="Open end gibt es nicht. Um 3:00 Uhr ist Schluss." />
            <WeddingAbcItem letter="P" text="Parkplätze sind an der Location ausreichend vorhanden." />
            <WeddingAbcItem letter="Q" text="Qualmen bitte nur draußen." />
            <WeddingAbcItem letter="R" text="Regen wünschen wir uns nicht, aber seid vorsichtshalber vorbereitet." />
            <WeddingAbcItem letter="S" text="Sitzordnung gilt nur für das Essen." />
            <WeddingAbcItem letter="T" text="Tränen bitte nur vor Freude." />
            <WeddingAbcItem letter="U" text="Unglaublich, bald ist es so weit." />
            <WeddingAbcItem letter="V" text="Vorfreude ist besonders groß." />
            <WeddingAbcItem letter="W" text="Weiß ist bitte der Braut vorbehalten." />
            <WeddingAbcItem letter="X" text="X-Beine sind kein Grund, nicht zu tanzen." />
            <WeddingAbcItem letter="Y" text="Yin und Yang: Wir beide gehören zusammen." />
            <WeddingAbcItem letter="Z" text="Zuletzt: Vielen Dank für euer Kommen." />
          </WeddingAbc>
        </div>
      </section>
      <section id="section-faq" className="align-center header-section">
        <div className="container">
          <h2 className="section-headline">FAQ</h2>
          <p>Hier findest du Antworten auf häufig gestellte Fragen.</p>
        </div>
      </section>
      <section>
        <div className="container">
          <Accordion>
            <AccordionItem title="Können wir unsere Kinder mitbringen?">
              <p className="faq-p">Selbstverständlich dürft ihr eure Kinder mitbringen. Bitte gebt Sie bei der Rückmeldung mit an, damit wir entsprechend planen können.</p>
            </AccordionItem>
            <AccordionItem title="Wie ist es mit Fotos während der Trauung?">
              <p className="faq-p">Unsere Fotografin begleitet uns den ganzen Tag und wird auch während der Trauung Fotos machen. Ihr könnt die Trauung daher entspannt genießen ohne euch selbst um Fotos kümmern zu müssen. Außerdem wollen wir in der Kirche lieber in föhliche Gesichter als in Handykameras schauen.</p>
            </AccordionItem>
            <AccordionItem title="Gibt es einen besonderen Dress Code?">
              <p className="faq-p">Dress Code gibt es keinen. Kommt, wie ihr euch wohlfühlt :-)</p>
            </AccordionItem>
          </Accordion>
        </div>
      </section>
      <section className="align-center footer-section">
        <div className="container">
          <h2>Wir freuen uns auf diesen besonderen Tag mit euch!</h2>
        </div>
      </section>
    </div>
  );
}
