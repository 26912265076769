import { ChangeEventHandler } from "react";
import styles from "./StringInput.module.css";

type StringInputProps = {
    placeholder: string,
    value: string,
    error?: string,
    onChange?: ChangeEventHandler<HTMLInputElement>
}

export default function StringInput(props: StringInputProps) {
    return <div className={styles.stringInput}>
        <input className={props.error ? "invalid" : "valid"}
            type="text"
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.onChange}
            required={true} />
        {props.error ? <div className="validation-message">{props.error}</div> : ''}
    </div>
}
