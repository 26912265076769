import useResizeObserver from "@react-hook/resize-observer";
import { PropsWithChildren, useEffect, useRef, useState } from "react";
import styles from "./StickyBar.module.css";

export default function StickyBar(props: PropsWithChildren) {
    const [isSticky, setIsSticky] = useState(false);
    const [containerHeight, setContainerHeight] = useState(0);

    const outer = useRef<HTMLDivElement>(null);
    const inner = useRef(null);

    useEffect(() => {
        function updateSticky() {
            const top = outer.current?.getBoundingClientRect().top ?? 0;
            setIsSticky(top < 0);
        }
        updateSticky();
        window.addEventListener("scroll", updateSticky);
        return () => {
            window.removeEventListener("scroll", updateSticky);
        }
    }, [outer]);

    useResizeObserver(inner, entry => {
        setContainerHeight(entry.contentRect.height);
    });

    return <div ref={outer} style={{minHeight: containerHeight + "px"}}>
        <div ref={inner} className={`${styles.inner} ${isSticky ? styles.sticky : ""}`}>
            {props.children}
        </div>
    </div>
}
