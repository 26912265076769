import { PropsWithChildren } from "react";
import styles from "./Organizers.module.css";

type OrganizerProps = {
    imagePath?: string,
    imageAlt?: string
}

export default function Organizers(props: PropsWithChildren<OrganizerProps>) {
    return <div>
        {props.imagePath
            ? <div className={styles.organizersImage}><img src={props.imagePath} alt={props.imageAlt} /></div>
            : ""}
        <div className={styles.organizers}>
            {props.children}
        </div>
    </div>
}